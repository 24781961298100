/* body {
  background: #fffdf9;
} */



.background-light {
  background: #F8F9FA;

}

.background-dark {
  background: #343A40;

}

.accent-dot {
  color: #92B8E6;   
}


.accent {
  background-color: #92B8E6;   
}


@media only screen and (max-width: 600px) {
  .content-row {
    padding-bottom: 40px;
  }
  .skill-row {
    padding-bottom: 40px;
  }
  .contact-form {
    padding-bottom: 68px;
  }
}

@media only screen and (min-width: 600px) {
  .content-row {
    padding-bottom: 125px;
  }
  .skill-row {
    padding-bottom: 60px;
  }
  .contact-form {
    padding-bottom: 168px;
  }
}

.foot {
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
}



.top-vec {
  margin: 0px;
  padding-top: 15%;

}

.tf-dark {
  color: #F8F9FA !important;
  outline-color:  #F8F9FA !important;
  border-color: #F8F9FA !important;
  background-color: #F8F9FA !important;

  

}




.tf-light {
  color: #343A40 !important;
  outline-color:  #343A40 !important;

}

.MuiOutlinedInput-notchedOutline {
  border-color: #777 !important;

}

.MuiFormLabel-root {
  color: #777 !important;
}

.MuiInputBase-input {
  color: #777 !important;
}

.MuiAlert-action {
  color: #777 !important;
}

.center-text {
  margin: 0;
  padding-top: 50%;
}

.center-pic-vert {
  padding-top: 20%;
  width: 100%;

}

.right-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;

}

.center {
  right: 50%;
  bottom: 50%;
  transform: translate(50%,50%);
  position: absolute;
}

.dark {
  color: #F8F9FA !important;
}

.light {
  color: #343A40 !important;
}

.lm-icon {
  color: #fffdf9
}

.accent-line {
  border-top: 3px solid  #92B8E6; 
  width: 50%; 
  float: left;
}

.transparent {
  background-color: transparent !important;
}

.MuiStepIcon-root.MuiStepIcon-active {
  color: #92B8E6 !important;
}

.center-text-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.form-row {
  padding-top: 15px;
}

